.createProject{
    border: 2px solid gray;
    border-radius: 1rem;
}


.projectsList{
    display:flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    /* width: 70vw; */
    /* border: 2px solid green; */
}

.project{
    margin: 2rem;
    padding: 2rem;
    border: 2px solid gray;
    border-radius: 1rem;
}
.default-bg{
    background: #f6f2ef;
}