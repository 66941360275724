//
// Base
//

// Body
body {
	background: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-fixed & {
			padding-top: get($header-config, desktop, fixed, height);
		}

		// Fixed Content Head
		.header-fixed.subheader-fixed.subheader-enabled & {
			// padding-top: get($header-config, desktop, fixed, height) + get($subheader-config, fixed, height);
		}

		// Fixed Aside
		.aside-fixed & {
			padding-left: get($aside-config, base, width);
		}

		// Minimize Aside
		.aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
			padding-left: get($aside-config, base, minimized-width);
		}
	}

	// Container
	.container,
	.container-fluid {
		padding: 0 get($page-padding, desktop);
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-mobile-fixed & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height);
		}

		// Fixed Subheader
		.header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height) + get($subheader-config, fixed, height-mobile);
		}
	}

	// Container
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($page-padding, tablet-and-mobile);
	}
}


.tag-container {
	border: 1px solid #ddd;
	padding: 5px;
	border-radius: 5px;
  }

  .items {
    display: inline-block;
    padding: 3px 9px;
    border: 1px solid #d4d4d8;
    font-family: Helvetica, sans-serif;
    border-radius: 5px;
    margin-right: 5px;
    background-color: #efebeb;
    cursor: pointer;
  }

  .tag-input {
	outline: none;
	border: none;
  }
  .automate-card{
	  background-color: #fffdd0;
  }
  .error-card{
	background-color:#f3cfd5;
}

.ant-divider-horizontal{
	min-width: unset !important;
}
#kt_header_mobile > a > img{
	// display: none;
}
.google-btn{
	text-align: center;
	margin-top: 10px;
}
// background-color: #e8e4e4;
// margin: 10px;
// padding: 2px 8px;
// border-radius: 20px;

.table-error-rows {
    background-color: #f3cfd5;
}
.ant-card-extra{
	// width: 50% ;
	padding: 0;
}
.ant-card-head-title{
	margin-top: -15px;
}
.ant-card-head{
	padding: 0 5px;
}
// .ant-table-cell:nth-child(2){
// 	text-align: center;
// }
.ant-table-cell:nth-child(3){
	text-align: center;
}
.ant-table-cell:nth-child(11){
	text-align: center;
}
.ant-table-wrapper {
	width: 108%;
	max-width: 108%;
}
.characters {
	list-style: none;
	padding-left: 0;
  }
  
  .characters li {
	display: flex;
	align-items: center;
	border: solid 2px #d0d0d0;
	border-radius: .2em;
	padding: .5em .8em .5em .5em;
	margin-bottom: 1em;
	background-color: white;
	width: 30%;
	margin: 0 auto;
  }
  
  .characters p {
	max-width: none;
	font-weight: bold;
	margin: 0;
  }
  
  .characters-thumb {
	overflow: hidden;
	flex-shrink: 0;
	width: 2em;
	height: 2em;
	background-color: #e8e8e8;
	padding: .5em;
	margin-right: .5em;
  }
  
  .characters-thumb img {
	display: block;
	width: 100%;
	height: auto;
  }
 