//
// Main Styles
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Initialize
@import "init";

// 2: Bootstrap/Material framework includes
@import "~bootstrap/scss/bootstrap";
@import "vendors/react/material-ui/general";

//
// Main Style
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Initialize
@import "init";

// 2: Bootstrap framework includes
@import "~bootstrap/scss/bootstrap";

// 3: Components
@import "components/utilities", "components/burger-icon", "components/card",
  "components/wizard", "components/type", "components/breadcrumb",
  "components/nav", "components/alert", "components/buttons", "components/code",
  "components/dropdown", "components/forms", "components/grid",
  "components/modal", "components/progress", "components/table",
  "components/popover", "components/tooltip", "components/accordion",
  "components/datatable", "components/checkbox", "components/radio",
  "components/switch", "components/option", "components/dialog",
  "components/strikethrough", "components/separator", "components/image-input",
  "components/label", "components/loader", "components/symbol",
  "components/pulse", "components/page-loader", "components/quick-search",
  "components/scrolltop", "components/spinner", "components/sticky-toolbar",
  "components/svg-icon", "components/svg", "components/timeline",
  "components/timeline-2", "components/timeline-3", "components/timeline-4",
  "components/timeline-5", "components/timeline-6", "components/overlay",
  "components/wave", "components/list", "components/bullet", "components/navi",
  "components/ribbon", "components/offcanvas", "components/toggle";

// 4: Plugins
@import "vendors/plugins/formvalidation", "vendors/plugins/dropzone",
  "vendors/plugins/perfect-scrollbar", "vendors/plugins/prismjs",
  "vendors/plugins/apexcharts";

// 5: Layout
@import "layout/init";

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

.login-logo {
  height: 145px !important;
  object-fit: cover;
  width: 54px;
}

.google-in {
  border: 1px solid gainsboro;
  background: white;
  padding: 12px 40px;
  border-radius: 6px;
  margin: 20px auto;
  display: block;
}
.login-form {
  padding: 30px;
  box-shadow: 4px 0px 10px 6px gainsboro;
  border-radius: 6px;
}
.app .ql-editor {
  min-height: 18em;
}
.home-icon{
   color: #787a7b;
    border-radius: 50%;
    width: 32px;
    padding: 7px;
    height: 32px;
    font-size: 16px;
    margin: 17px;
    background: #abadaf;
    cursor: pointer;
}

.dis-adj{
  display: flex;
}
#top-mobile>.topbar{
  background-color: #ffffff00 !important;
  width: 100%;
}
#top-mobile .d-none{
  display: none !important;
}
#user-tog{
  margin-right: 10px;
}
@media (max-width:778px){
  .dis-adj{
    display: block;
  }
  .btn-adj-1{
    margin: 20px auto;
    display: block;
  }
  .divivder-adj{
    padding-right: 0px !important;
  }
 

}
@media (max-width:997px){

  .custom-logo {
    display: none;
  }
  .home-icons {
    display: none;
  }
  #user-tog{
    margin-top: 10px;
  }
  .user-adj {
    right: -18px !important;
    top:-4px !important;
  }
}

.user-adj {
  right: -50px;
  position: absolute;
  line-height:2.7;
  border-radius: 4px;
  padding: 4px 10px;
  top: 10px;
}
.user-adj:hover{
  background-color:#f3f6f9;
  cursor: pointer;
}
.link-adj{
   text-align: center;
  margin: 0 auto;
  display: block;
  line-height: 2;
  color: white;
}
.link-adj:hover{
  text-decoration: underline !important;
  color: white !important;

}